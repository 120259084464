import * as Msdyn365 from '@msdyn365-commerce/core';
import { ErrorCode } from '../modules/order-details';

/**
 * GetOrderHistory Input Action
 */
export class GetOrderHistoryInput implements Msdyn365.IActionInput {
    public userToken: string;
    constructor(ctx: Msdyn365.ICreateActionContext) {
        this.userToken = ctx.requestContext.user.token;
    }

    public getCacheKey = () => `GetOrderHistory-${this.userToken}`;
    public getCacheObjectType = () => 'GetOrderHistory';
    public dataCacheType = (): Msdyn365.CacheType => 'none';
}

export interface IOrderHistoryLine {
    OrderNumber: string;
    LineNumber: string;
    ProductName: string;
    Status: string;
    RequestedQty: string;
    RequestedDate?: string;
    RequestedUnit: string;
    Currency: string;
    NetValue: string;
    ScheduleLines: IOrderHistorySchedulLine[];
    ExtensionProperties: any[];
}
export interface IOrderHistorySchedulLine {
    OrderNumber: string;
    LineNumber: string;
    ScheduleLineNumber: string;
    ConfirmedQty: string;
    ConfirmedUnit: string;
    ConfirmedDate?: string;
    ExtensionProperties: any[];
}

export interface IGetOrderHistoryData {
    '@odata.context'?: string;
    error?: ErrorCode;
    value?: IOrderHistoryTableData[];
    '@odata.nextLink'?: string;
}
export interface IOrderHistoryTableData {
    OrderNumber: string;
    PoNumber: string;
    Status: string;
    ConfirmedDate: string;
    SoldToId: string;
    SoldToName: string;
    ShipToId: string;
    ShipToName: string;
    RecordCreatedDate: string;
    OrderLines: IOrderHistoryLine[];
    ExtensionProperties: any[];
    highlight: boolean;
    highlightOrderLines: boolean;
}

const createInput = (args: Msdyn365.ICreateActionContext): Msdyn365.IActionInput => {
    return new GetOrderHistoryInput(args);
};

async function action(_: GetOrderHistoryInput, ctx: Msdyn365.IActionContext): Promise<IGetOrderHistoryData> {
    const response = await Msdyn365.sendRequest<IGetOrderHistoryData>(
        `${ctx.requestContext.apiSettings.baseUrl}Commerce/Orders/GetAll(language='${ctx.requestContext.locale}')`,
        'get',
        {},
        {
            headers: {
                OUN: ctx.requestContext.apiSettings.oun,
                Authorization: `id_token ${ctx.requestContext.user.token}`
            }
        }
    );

    if (response.status === 200 && response.data) {
        return { ...response.data };
    }
    return { ...({} as IGetOrderHistoryData), error: { errorCode: response.status, errorText: response.statusText } };
}

export default Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<IGetOrderHistoryData>>action,
    id: 'GetOrderHistory',
    input: createInput
});
